// import { Routes, Route } from "react-router-dom"
// import ContactUsPage from "../pages/contact-us"
// import HomePage from "../pages/home"
// import OurServicePage from "../pages/our-service"
// import WhoWeArePage from "../pages/who-we-are"
// import your route components too
import React from "react"

const RouteMenu = () => {
  return (
    <div>
      {/* <Routes>
        <Route path="/" />
        <Route path="/ourservice" />
        <Route path="/whoweare" />
        <Route path="/contactus" />
      </Routes> */}
    </div>
  )
}

export default RouteMenu
