import React from "react"
import HeaderImageWhite from "../../asset/image/HeaderImageWhite.png"
import HeaderImageRed from "../../asset/image/HeaderImageRed.png"
import Button from "react-bootstrap/Button"
import { FormattedMessage } from "react-intl"
import ButtonPrimary from "../../components/ButtonPrimary"
import { Link } from "react-scroll"
// import your route components too

const HomePage = (props) => {
  const { local } = props
  return (
    <div name="home">
      <div className="banner-image">
        <div className="layout-size-image-white">
          <img className="size-image-white" src={HeaderImageWhite} />
        </div>
        <div className="layout-size-image-white">
          <img className="size-image-red" src={HeaderImageRed} />
        </div>
      </div>
      <div className="padding-content-home margin-content-home">
        <p className="font-title-en color-1d3557">
          <FormattedMessage id="homeTitle" />
        </p>
        <p className={`font-content-${local} color-1d3557`}>
          <FormattedMessage id="homeContent" />
        </p>
        <div className="btn-layout">
          <Link
            activeClass="active"
            to="contactus"
            spy={true}
            smooth={true}
            // offset={50}
            duration={500}
          >
            <ButtonPrimary locale={local} text={<FormattedMessage id="homeBtnContactUs" />} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomePage
