import './App.css';
import Content from './layouts/content/content';
import RouteMenu from './routes/route';
import Footer from './layouts/footer';
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import LangEn from './lang/en.json';
import LangTH from './lang/th.json';
import Header from './layouts/header/index';
import ComponentCookie from './routes/cookie';
import SendEmailWeb from './components/sendEmailWeb';
import SendEmailMobile from './components/sendEmailMobile';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function App() {
  const [messege, setMessege] = useState(LangEn);
  const [searchParams, setSearchParams] = useSearchParams();
  const [local, setLocal] = useState('en');
  const [routeValue, setRouteValue] = useState('home');
  const [sendEmailVisible, setSendEmailVisible] = useState(false);
  const [sendEmailMobileVisible, setSendEmailMobileVisible] = useState(false);
  const [visibleCookieTab, setVisibleCookieTab] = useState('show');
  const [checkedSwitchCookie, setCheckedSwitchCookie] = useState({
    strictly: true,
    analytical: false,
    functionality: false,
    target: false,
    advertising: false,
  });
  const [visibleCookieSettingMobile, setVisibleCookieSettingMobile] = useState(false);
  const [visibleCookieSettingWeb, setVisibleCookieSettingWeb] = useState(false);
  const [visibleCookieMobile, setVisibleCookieMobile] = useState(false);
  const [policyMobileVisible, setPolicyMobileVisible] = useState(false);
  const [policyStatus, setPolicyStatus] = useState(false);
  const [policyVisible, setPolicyVisible] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [visibleCookieWeb, setVisibleCookieWeb] = useState(false);
  

  useEffect(() => {
    if (searchParams.get('privacy') === 'true') {
      setPolicyVisible(true);
      setLocal('th');
      setMessege(LangTH);
    }
  }, []);

  const switchLang = (value) => {
    if (value === 'en') {
      setMessege(LangEn);
      setLocal('en');
    } else {
      setMessege(LangTH);
      setLocal('th');
    }
    setTimeout(() => {
      setToggleMenu(false);
    }, 1000);
  };

  const RouteChecked = (value) => {
    setRouteValue(value);
    setTimeout(() => {
      setToggleMenu(false);
    }, 1000);
  };

  const handleToggleOpenMenu = (e) => {
    setToggleMenu(!toggleMenu);
  };

  const handleShowPolicyModal = () => {
    setPolicyStatus(false);
    setPolicyVisible(true);
  };

  const handleHideSendEmailModal = () => {
    setSendEmailVisible(false);
    setSendEmailMobileVisible(false);
  };

  const handleShowPolicyMobileModal = () => {
    setPolicyStatus(false);
    setPolicyMobileVisible(true);
  };

  return (
    <IntlProvider locale={local} messages={messege}>
      <Header
        switchLang={switchLang}
        local={local}
        routeValue={routeValue}
        RouteChecked={RouteChecked}
        toggleMenu={toggleMenu}
        handleToggleOpenMenu={handleToggleOpenMenu}
      />
      <div className='App container-xl'>
        <RouteMenu switchLang={switchLang} local={local} />
        <Content local={local} setSendEmailVisible={setSendEmailVisible} setSendEmailMobileVisible={setSendEmailMobileVisible} />
        <Footer locale={local} handleShowPolicyModal={handleShowPolicyModal} setVisibleCookieWeb={setVisibleCookieWeb} />
      </div>
      <ComponentCookie
        local={local}
        setVisibleCookieTab={setVisibleCookieTab}
        setPolicyStatus={setPolicyStatus}
        setPolicyVisible={setPolicyVisible}
        setPolicyMobileVisible={setPolicyMobileVisible}
        setVisibleCookieMobile={setVisibleCookieMobile}
        setVisibleCookieWeb={setVisibleCookieWeb}
        setVisibleCookieSettingMobile={setVisibleCookieSettingMobile}
        setVisibleCookieSettingWeb={setVisibleCookieSettingWeb}
        setToggleMenu={setToggleMenu}
        toggleMenu={toggleMenu}
        checkedSwitchCookie={checkedSwitchCookie}
        setCheckedSwitchCookie={setCheckedSwitchCookie}
        visibleCookieTab={visibleCookieTab}
        policyVisible={policyVisible}
        policyMobileVisible={policyMobileVisible}
        visibleCookieWeb={visibleCookieWeb}
        visibleCookieMobile={visibleCookieMobile}
        visibleCookieSettingWeb={visibleCookieSettingWeb}
        visibleCookieSettingMobile={visibleCookieSettingMobile}
      />

      <SendEmailWeb
        locale={local}
        handleShowPolicyModal={handleShowPolicyModal}
        policyStatus={policyStatus}
        show={sendEmailVisible}
        onHide={handleHideSendEmailModal}
      />

      <SendEmailMobile
        locale={local}
        handleShowPolicyModal={handleShowPolicyMobileModal}
        policyStatus={policyStatus}
        show={sendEmailMobileVisible}
        onHide={handleHideSendEmailModal}
      />
    </IntlProvider>
  );
}

export default App;
