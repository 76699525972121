import React from "react"
import { FormattedMessage } from "react-intl"
import WhoWeAre_Image from "../../asset/image/WhoWeAre_Image.png"
import Line from "../../asset/image/Line.png"

const WhoWeArePage = (props) => {
  const { local } = props
  return (
    <div name="whoweare">
      <div className="container-image">
        <img
          className="container-image-centered"
          src={WhoWeAre_Image}
          alt="image-who-we-are"
        />
        <div className="centered-content">
          <img src={Line} className="line-title" />
          <p className={`font-title-${local} color-fff`}>
            <FormattedMessage id="whoWeAre-title" />
          </p>
          <p className={`font-content-our-service-${local} color-fff`}>
            <FormattedMessage id="whoWeAre-1" />
          </p>
          <p className={`font-content-our-service-${local} color-fff`}>
            <FormattedMessage id="whoWeAre-2" />
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhoWeArePage
