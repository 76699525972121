import { FormattedMessage } from "react-intl"
import ContactUsPage from "../../pages/contact-us"
import HomePage from "../../pages/home"
import OurServicePage from "../../pages/our-service"
import WhoWeArePage from "../../pages/who-we-are"

const Content = (props) => {
  const { setSendEmailVisible, setSendEmailMobileVisible, local } = props

  return (
    <div>
      <HomePage local={local} />
      <OurServicePage local={local} />
      <WhoWeArePage local={local} />
      <ContactUsPage
        local={local}
        setSendEmailVisible={setSendEmailVisible}
        setSendEmailMobileVisible={setSendEmailMobileVisible}
      />
    </div>
  )
}

export default Content
