import React, { useState } from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { Link } from "react-scroll"
import { FormattedMessage } from "react-intl"
import RapidTechLogo from '../../asset/image/RapidTech_NoBG.png'
import '../../asset/css/navbar.css'

const Header = (props) => {
  const { switchLang, local, routeValue, RouteChecked, handleToggleOpenMenu, toggleMenu } = props
  const locale = local;

  const [isScroll, setIsScroll] = useState(false);

  const onScroll = () => {
    if (window.scrollY >= 1) {
      setIsScroll(true);
    }
    else {
      setIsScroll(false);
    }
  };

  window.addEventListener('scroll', onScroll);

  return (
    <Navbar className={`navbar-customize navbar-customize-lang-${locale} ${isScroll ? 'navbar-customize-sticky' : 'navbar-customize-fixed'} ${toggleMenu ? 'navbar-customize-sticky-fixd-open' : null}`} sticky='top' expand='lg' collapseOnSelect expanded={toggleMenu ? 'expanded' : false}>
      <Container>
        <Navbar.Brand as={NavLink} to="/" exact>
          <img src={RapidTechLogo} style={{ width: "135px", height: "45px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggleOpenMenu} className='navbar-toggle-color-black' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {
              toggleMenu ? <Nav style={{ textAlign: 'center', marginTop: '42px' }} className='navbar-hidden-burger'>
                <Nav.Link as={NavLink} to="/" exact >
                  <Link
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    // offset={50}
                    duration={500}
                    onClick={() => RouteChecked("home")}
                  >
                    <FormattedMessage id="homeHeader" />
                  </Link>
                </Nav.Link>
                <Nav.Link as={NavLink} to="/ourservice" key="ourservice">
                  <Link
                    activeClass="active"
                    to="ourservice"
                    spy={true}
                    smooth={true}
                    // offset={50}
                    duration={500}
                    onClick={() => RouteChecked("ourservice")}
                  >
                    <FormattedMessage id="ourServicesHeader" />
                  </Link>
                </Nav.Link>
                <Nav.Link as={NavLink} to="/whoweare" key="whoweare" >
                  <Link
                    activeClass="active"
                    to="whoweare"
                    spy={true}
                    smooth={true}
                    // offset={50}
                    duration={500}
                    onClick={() => RouteChecked("whoweare")}
                  >
                    <FormattedMessage id="whoWeAreHeader" />
                  </Link>
                </Nav.Link>
                <Nav.Link as={NavLink} to="/contactus" key="contactus" >
                  <Link
                    activeClass="active"
                    to="contactus"
                    spy={true}
                    smooth={true}
                    // offset={50}
                    duration={500}
                    onClick={() => RouteChecked("contactus")}
                  >
                    <FormattedMessage id="contactHeader" />
                  </Link>
                </Nav.Link>
                <div className='navbar-lang-align'>
                  <span className={local === "en" ? "text-lang-style-checked" : "text-lang-style-unchecked"} onClick={() => switchLang("en")}>EN</span>&nbsp;|&nbsp;
                  <span className={local === "th" ? "text-lang-style-checked" : "text-lang-style-unchecked"} onClick={() => switchLang("th")}>TH</span>
                </div>
              </Nav> : null
            }
            <Nav className="navbar-hidden-row">
              <Nav.Link as={NavLink} to="/" exact >
                <Link
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  // offset={50}
                  duration={500}
                  onClick={() => RouteChecked("home")}
                >
                  <FormattedMessage id="homeHeader" />
                </Link>
              </Nav.Link>
              <Nav.Link as={NavLink} to="/ourservice" key="ourservice">
                <Link
                  activeClass="active"
                  to="ourservice"
                  spy={true}
                  smooth={true}
                  // offset={50}
                  duration={500}
                  onClick={() => RouteChecked("ourservice")}
                >
                  <FormattedMessage id="ourServicesHeader" />
                </Link>
              </Nav.Link>
              <Nav.Link as={NavLink} to="/whoweare" key="whoweare" >
                <Link
                  activeClass="active"
                  to="whoweare"
                  spy={true}
                  smooth={true}
                  // offset={50}
                  duration={500}
                  onClick={() => RouteChecked("whoweare")}
                >
                  <FormattedMessage id="whoWeAreHeader" />
                </Link>
              </Nav.Link>
              <Nav.Link as={NavLink} to="/contactus" key="contactus" >
                <Link
                  activeClass="active"
                  to="contactus"
                  spy={true}
                  smooth={true}
                  // offset={50}
                  duration={500}
                  onClick={() => RouteChecked("contactus")}
                >
                  <FormattedMessage id="contactHeader" />
                </Link>
              </Nav.Link>
              <div style={{ padding: '8px' }}>
                <span className={local === "en" ? "text-lang-style-checked" : "text-lang-style-unchecked"} onClick={() => switchLang("en")}>EN</span>&nbsp;|&nbsp;
                <span className={local === "th" ? "text-lang-style-checked" : "text-lang-style-unchecked"} onClick={() => switchLang("th")}>TH</span>
              </div>
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  )
}

export default Header
