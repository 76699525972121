import React from "react"
import Card from "react-bootstrap/Card"
import { FormattedMessage } from "react-intl"
import Contact_Facebook from "../../asset/image/Contact_Facebook.png"
import Contact_LinkedIn from "../../asset/image/Contact_LinkedIn.png"
import Contact_Mail from "../../asset/image/Contact_Mail.png"
import Line from "../../asset/image/Line.png"

const ContactUsPage = (props) => {
  const { setSendEmailVisible, setSendEmailMobileVisible, local } = props
  const image = [
    {
      id: "email",
      image: Contact_Mail
    },
    {
      id: "facebook",
      image: Contact_Facebook
    },
    {
      id: "linkedin",
      image: Contact_LinkedIn
    }
  ]
  return (
    <div name="contactus">
      <div className="padding-content">
        <div className="container-layout-card-contact">
          <div className="div-layout-card-contact">
            <p className={`font-title-${local} color-1d3557 w-title`}>
              <img src={Line} className="line-title" />
              <FormattedMessage id="contactUs-title" />
            </p>
            <p className={`font-content-${local} color-1d3557 w-320`}>
              <FormattedMessage id="contactUs-1" />
            </p>
          </div>

          <div className="layout-card-contact">
            <a className="display-mail-w">
              <Card
                className="card-style-social mrg-rl-10 card-"
                onClick={() => setSendEmailVisible(true)}
              >
                <Card.Body className="pd-card-social">
                  <img className="icon-social" src={Contact_Mail} alt={1} />
                </Card.Body>
              </Card>
            </a>
            <a className="display-mail-m">
              <Card
                className="card-style-social mrg-rl-10 card-"
                onClick={() => setSendEmailMobileVisible(true)}
              >
                <Card.Body className="pd-card-social">
                  <img className="icon-social" src={Contact_Mail} alt={2} />
                </Card.Body>
              </Card>
            </a>
            <a href="https://www.facebook.com/rapidtech.th" target="_blank">
              <Card
                className="card-style-social mrg-rl-10 card-"
                // onClick={() => item.id === "email" ? setSendEmailVisible(true) : null}
              >
                <Card.Body className="pd-card-social">
                  <img className="icon-social" src={Contact_Facebook} alt={3} />
                </Card.Body>
              </Card>
            </a>
            <a href="https://www.linkedin.com/company/rapidtechth" target="_blank">
              <Card
                className="card-style-social mrg-rl-10 card-"
                // onClick={() => item.id === "email" ? setSendEmailVisible(true) : null}
              >
                <Card.Body className="pd-card-social">
                  <img className="icon-social" src={Contact_LinkedIn} alt={4} />
                </Card.Body>
              </Card>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsPage
