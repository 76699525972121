import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Container, Modal, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import '../asset/css/cookie-setting-mobile.css'

export default (props) => {
  const modalProps = { ...props };
  _.unset(modalProps, ['locale']);
  const { locale, show, checkedSwitchCookie, onSwitchCookie, SetAllCookies, clearCookie } = props;
  const [statusRead, setStatusRead] = useState(true);
  const listInnerRef = useRef();

  useEffect(() => {
    setStatusRead(true);
  }, [show]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= (scrollHeight - 50)) {
        setStatusRead(false);
      }
    }
  };

  return (
    <Modal
      {...modalProps}
      className='cookie-modal-mobile'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton onClick={clearCookie}></Modal.Header>
      <h1 className={`contact-policy-header-${locale}`} style={{ marginBottom: '10px' }}><FormattedMessage id='cookie-st-1' /></h1>
      <div
        className={`contact-policy-content`}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        <Modal.Body>
          <Container fluid='xl'>
            <Row>
              <Col lg={12}>
                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-35 cookie-modal-switch-setting`}>
                  <FormattedMessage id='cookie-st-2' />
                  <span style={{ color: '#c4c4c4' }}><FormattedMessage id='cookie-st-10' /></span>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                  <FormattedMessage id='cookie-st-3' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                  <FormattedMessage id='cookie-st-4' />
                  <label class="cookie-modal-switch">
                    <input type="checkbox" checked={_.get(checkedSwitchCookie, 'analytical')} onClick={() => onSwitchCookie('analytical')} />
                    <span class="cookie-modal-slider round"></span>
                  </label>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                  <FormattedMessage id='cookie-st-5' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                  <FormattedMessage id='cookie-st-6' />
                  <label class="cookie-modal-switch">
                    <input type="checkbox" checked={_.get(checkedSwitchCookie, 'functionality')} onClick={() => onSwitchCookie('functionality')} />
                    <span class="cookie-modal-slider round"></span>
                  </label>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                  <FormattedMessage id='cookie-st-7' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                  <FormattedMessage id='cookie-st-11' />
                  <label class="cookie-modal-switch">
                    <input type="checkbox" checked={_.get(checkedSwitchCookie, 'target')} onClick={() => onSwitchCookie('target')} />
                    <span class="cookie-modal-slider round"></span>
                  </label>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                  <FormattedMessage id='cookie-st-12' />
                </p>


                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                  <FormattedMessage id='cookie-st-13' />
                  <label class="cookie-modal-switch">
                    <input type="checkbox" checked={_.get(checkedSwitchCookie, 'advertising')} onClick={() => onSwitchCookie('advertising')} />
                    <span class="cookie-modal-slider round"></span>
                  </label>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                  <FormattedMessage id='cookie-st-14' />
                </p>

              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </div>
      <Row lg={12} style={{ margin: '0px 15px 10px 15px' }}>
        <Col lg={6} xs={6} className='t-a-c' style={{ padding: 'unset' }}>
          <Button className='cookie-modal-button-confirm' onClick={() => SetAllCookies()}><FormattedMessage id='cookie-st-8' /></Button>
        </Col>
        <Col lg={6} xs={6} className='t-a-c' style={{ padding: 'unset' }}>
          <Button className='cookie-modal-button-accept' onClick={() => SetAllCookies('ALL')}><FormattedMessage id='cookie-st-9' /></Button>
        </Col>
      </Row>
    </Modal >
  );
}