import Button from "react-bootstrap/Button"

function ButtonPrimary(props) {
  const { text, locale } = props
  return (
    <>
      <style type="text/css">
        {`
            .btn-flat {
                background-color: #e73845;
                color: white;
            }

            .btn-xxl {
                width: 150px;
            }
        `}
      </style>

      <Button variant="flat" size="xxl" className={locale === "en" ? "font-button-en" : "font-button-th"}>
        {text}
      </Button>
    </>
  )
}

export default ButtonPrimary
