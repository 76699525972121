import React, { useState, useEffect } from 'react'
import CookieConsent, { Cookies } from "react-cookie-consent"
import '../asset/css/cookie.css'
import { FormattedMessage } from 'react-intl'
import TagManager from 'react-gtm-module'
import ModalPrivacyWeb from '../components/policyWeb'
import ModalPrivacyMobile from '../components/policyMobile'
import ModalCookieWeb from '../components/cokieWeb'
import ModalCookieMobile from '../components/cookieMobile'
import ModalCookieSettingWeb from '../components/cookieSettingWeb'
import ModalCookieSettingMobile from '../components/cookieSettingMobile'

export default (props) => {
  const { local, setVisibleCookieTab, setPolicyStatus, setPolicyVisible, setPolicyMobileVisible, setVisibleCookieMobile, setVisibleCookieWeb, setVisibleCookieSettingMobile,
    setVisibleCookieSettingWeb, setToggleMenu, toggleMenu, checkedSwitchCookie, setCheckedSwitchCookie, visibleCookieTab, policyVisible, policyMobileVisible,
    visibleCookieWeb, visibleCookieMobile, visibleCookieSettingWeb, visibleCookieSettingMobile } = props
  const locale = local



  const tagManagerArgs = {
    gtmId: 'GTM-K92XSKS'
  }

  let cookieAyt = Cookies.get('rapidTechcookie2')
  let cookieStl = Cookies.get('rapidTechcookie1')

  useEffect(() => {
    if (cookieAyt !== undefined) {
      TagManager.initialize(tagManagerArgs)
    }
  }, [cookieAyt]);

  useEffect(() => {
    if (cookieAyt !== undefined) {
      TagManager.initialize(tagManagerArgs)
    }
  }, [cookieAyt]);

  useEffect(() => {
    if (cookieStl !== undefined) {
      setVisibleCookieTab('hidden')
    }
  }, [cookieStl]);

  const handleShowPolicyModal = () => {
    setPolicyStatus(false);
    setPolicyVisible(true);
  }


  const handleShowPolicyMobileModal = () => {
    setPolicyStatus(false);
    setPolicyMobileVisible(true);
  }

  const handleHidePolicyModal = (status) => {
    if (status) setPolicyStatus(status);
    setPolicyVisible(false);
    setPolicyMobileVisible(false)
  }

  const handleShowCookieModal = () => {
    setVisibleCookieMobile(true)
  }

  const handleShowCookieWebModal = () => {
    setVisibleCookieWeb(true)
  }

  const handleHideCookieModal = () => {
    setVisibleCookieMobile(false)
    setVisibleCookieSettingMobile(false)
  }

  const handleHideCookieMobileModal = () => {
    setVisibleCookieWeb(false)
    setVisibleCookieSettingWeb(false)
  }



  // const handleToggleOpenMenu = (e) => {
  //   console.log('-----------------------------------------------');
  //   setToggleMenu(!toggleMenu);
  // }

  // const handleToggleCloseMenu = (e) => {
  //   setToggleMenu(false);
  // }

  const SetAllCookies = (type) => {
    if (type === 'ALL') {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      Cookies.set('rapidTechcookie2', 'Analytical/Performance Cookies', { path: "/", expires: tomorrow })
      Cookies.set('rapidTechcookie3', 'Functionality Cookies', { path: "/", expires: tomorrow })
      Cookies.set('rapidTechcookie4', 'Targeting Cookies', { path: "/", expires: tomorrow })
      Cookies.set('rapidTechcookie5', 'Advertising Cookies', { path: "/", expires: tomorrow })
      TagManager.initialize(tagManagerArgs)
    } else {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      if (checkedSwitchCookie.analytical === true) {
        Cookies.set('rapidTechcookie2', 'Analytical/Performance Cookies', { path: "/", expires: tomorrow })
        TagManager.initialize(tagManagerArgs)
      }
      if (checkedSwitchCookie.functionality === true) {
        Cookies.set('rapidTechcookie3', 'Functionality Cookies', { path: "/", expires: tomorrow })
      }
      if (checkedSwitchCookie.target === true) {
        Cookies.set('rapidTechcookie4', 'Targeting Cookies', { path: "/", expires: tomorrow })
      }
      if (checkedSwitchCookie.advertising === true) {
        Cookies.set('rapidTechcookie5', 'Advertising Cookies', { path: "/", expires: tomorrow })
      }
    }

    setVisibleCookieSettingMobile(false)
    setVisibleCookieSettingWeb(false)
    setVisibleCookieTab('hidden')
    // console.log('Cookies',)
  }

  const clearCookie = () => {
    Cookies.remove('rapidTechcookie1')
    setVisibleCookieTab('show')
  }

  const SettingCookiesMobile = () => {
    setVisibleCookieSettingMobile(true)
  }

  const SettingCookies = () => {
    setVisibleCookieSettingWeb(true)
  }

  const onSwitchCookie = (type) => {
    setCheckedSwitchCookie(prevState => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  }

  return (
    <div>
      <div className='hide-cookie-web'>
        <CookieConsent
          enableDeclineButton
          flipButtons={true}
          location='bottom'
          onAccept={() => SetAllCookies('ALL')}
          onDecline={SettingCookies}
          containerClasses='cookie-content-background'
          buttonClasses={`cookie-content-button-${locale}`}
          declineButtonClasses={`cookie-content-setting-${locale}`}
          buttonText={<FormattedMessage id='cookie-button-acept' />}
          declineButtonText={<FormattedMessage id='cookie-button-decline' />}
          hideOnDecline={false}
          setDeclineCookie={true}
          cookieName='rapidTechcookie1'
          cookieValue='Strictly Necessary Cookies'
          expires={1}
          visible={visibleCookieTab}
        >
          <p className={`cookie-content-text-1-${locale}`}><FormattedMessage id='cookie-text-1' /></p>
          <span className={`cookie-content-text-2-${locale}`}>
            <FormattedMessage id='cookie-text-7' />  <br />
            <FormattedMessage id='cookie-text-8' />&nbsp;<span style={{ color: '#e73845', cursor: 'pointer' }} className={`cookie-content-text-2-${locale}`} onClick={handleShowCookieWebModal}><FormattedMessage id='cookie-text-6' /></span>
          </span>
        </CookieConsent>
      </div>

      <div className='hide-cookie-mobile'>
        <CookieConsent
          enableDeclineButton
          flipButtons={true}
          location='bottom'
          onAccept={() => SetAllCookies('ALL')}
          onDecline={SettingCookiesMobile}
          containerClasses='cookie-content-background-mobile'
          buttonClasses={`cookie-content-button-mobile-${locale}`}
          declineButtonClasses={`cookie-content-setting-mobile-${locale}`}
          buttonText={<FormattedMessage id='cookie-button-acept' />}
          declineButtonText={<FormattedMessage id='cookie-button-decline' />}
          hideOnDecline={false}
          setDeclineCookie={true}
          cookieName='rapidTechcookie1'
          cookieValue='Strictly Necessary Cookies'
          expires={1}
          visible={visibleCookieTab}
        >
          <p className={`cookie-content-text-mobile-1-${locale}`}><FormattedMessage id='cookie-text-1' /></p>
          <div style={{ textAlign: 'center' }}>
            <span className={`cookie-content-text-2-${locale}`}>
              <FormattedMessage id='cookie-text-2' />
              &nbsp;<span style={{ color: '#e73845' }} className={`cookie-content-text-2-${locale}`} onClick={handleShowCookieModal}>"<FormattedMessage id='cookie-text-6' />"</span>
            </span>
          </div>
        </CookieConsent>

        <ModalPrivacyWeb
          locale={locale}
          show={policyVisible}
          onHide={handleHidePolicyModal}
          backdrop='static'
          keyboard={false}
        />

        <ModalPrivacyMobile
          locale={locale}
          show={policyMobileVisible}
          onHide={handleHidePolicyModal}
          backdrop='static'
          keyboard={false}
        />

        <ModalCookieWeb
          locale={locale}
          show={visibleCookieWeb}
          onHide={handleHideCookieMobileModal}
          backdrop='static'
          keyboard={false}
          handleShowPolicyModal={handleShowPolicyModal}
        />

        <ModalCookieMobile
          locale={locale}
          show={visibleCookieMobile}
          onHide={handleHideCookieModal}
          backdrop='static'
          keyboard={false}
          handleShowPolicyMobileModal={handleShowPolicyMobileModal}
        />

        <ModalCookieSettingWeb
          locale={locale}
          show={visibleCookieSettingWeb}
          onHide={handleHideCookieMobileModal}
          backdrop='static'
          keyboard={false}
          checkedSwitchCookie={checkedSwitchCookie}
          onSwitchCookie={onSwitchCookie}
          SetAllCookies={SetAllCookies}
          clearCookie={clearCookie}
        />

        <ModalCookieSettingMobile
          locale={locale}
          show={visibleCookieSettingMobile}
          onHide={handleHideCookieModal}
          backdrop='static'
          keyboard={false}
          checkedSwitchCookie={checkedSwitchCookie}
          onSwitchCookie={onSwitchCookie}
          SetAllCookies={SetAllCookies}
          clearCookie={clearCookie}
        />


      </div>
    </div>
  )
}
