import React from "react"
import Card from "react-bootstrap/Card"
import Icon_Digital_Patform from "../../asset/image/Icon_Digital_Patform.png"
import Icon_Cloud_Services from "../../asset/image/Icon_Cloud_Services.png"
import Icon_IoT from "../../asset/image/Icon_IoT.png"
import Icon_SoftwareDev from "../../asset/image/Icon_SoftwareDev.png"
import Line from "../../asset/image/Line.png"
import { FormattedMessage } from "react-intl"

const OurServicePage = (props) => {
  const { local } = props
  const infoService = [
    {
      id: "info1",
      img: Icon_Digital_Patform,
      title: <FormattedMessage id="ourService-1" />,
      content: <FormattedMessage id="ourService-1-1" />
    },
    {
      id: "info4",
      img: Icon_SoftwareDev,
      title: <FormattedMessage id="ourService-2" />,
      content: <FormattedMessage id="ourService-2-1" />
    },
    {
      id: "info2",
      img: Icon_Cloud_Services,
      title: <FormattedMessage id="ourService-3" />,
      content: <FormattedMessage id="ourService-3-1" />
    },
    {
      id: "info3",
      img: Icon_IoT,
      title: <FormattedMessage id="ourService-4" />,
      content: <FormattedMessage id="ourService-4-1" />
    }
  ]
  return (
    <div name="ourservice">
      <div className="padding-content">
        <img src={Line} className="line-title" alt="Line-Logo"/>
        <p className={`font-title-${local}`}>
          <FormattedMessage id="ourService-title" />
        </p>
        <div className="content-list-card">
          {infoService.map((item, index) => (
            <Card

              className="mt-mb-16 card-style"
            // style={{ width: "210px", height: "300px" }}
            >
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginTop: "12px",
                  padding: "16px"

                }}
                name={`card.${index}`}
              >
                <img
                  style={{ display: "flex", width: "40px" }}
                  src={item.img}
                  alt={`${index}`}

                />
                <div className="content-in-card">
                  <p className={`title-card-service-${local}`}>{item.title}</p>
                  <p className={`content-card-service-${local}`}>
                    {item.content}
                  </p>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurServicePage
