import { Nav } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { Link } from "react-scroll"
import { FormattedMessage } from "react-intl"
import Footer_Facrbook from "../../asset/image/Footer_Facrbook.png"
import Footer_linkedin from "../../asset/image/Footer_LinkedIn.png"
import Footer_line from "../../asset/image/Line.png"
// import Shape_Footer from "../../asset/image/Shape_Footer.png"
const Footer = (props) => {
  const { locale, handleShowPolicyModal, setVisibleCookieWeb } = props
  const infoService = [
    {
      id: "info1",
      title: <FormattedMessage id="ourService-1" />,
      content: <FormattedMessage id="ourService-1-1" />
    },
    {
      id: "info4",
      title: <FormattedMessage id="ourService-2" />,
      content: <FormattedMessage id="ourService-2-1" />
    },
    {
      id: "info2",
      title: <FormattedMessage id="ourService-3" />,
      content: <FormattedMessage id="ourService-3-1" />
    },
    {
      id: "info3",

      title: <FormattedMessage id="ourService-4" />,
      content: <FormattedMessage id="ourService-4-1" />
    }
  ]
  return (
    <div style={{ backgroundColor: "#eee" }}>
      <div className={`footermain footer-customize-lang-${locale}`}>
        <div className="footerrapidtechzone">
          <div className="footertopicname">RapidTech</div>
          <div className="footerrapidtechcontent">
            <Nav.Link as={NavLink} to="/" exact>
              <Link
                style={{ width: "220px", height: "260px" }}
                activeClass="active"
                to="home"
                smooth={true}
                // offset={50}
                duration={500}
                className="footerlink"
              >
                <FormattedMessage id="homeHeader" />
              </Link>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/ourservice">
              <Link
                style={{ width: "220px", height: "260px" }}
                activeClass="active"
                to="ourservice"
                smooth={true}
                // offset={50}
                duration={500}
                className="footerlink"
              >
                <FormattedMessage id="ourServicesHeader" />
              </Link>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/whoweare">
              <Link
                style={{ width: "220px", height: "260px" }}
                activeClass="active"
                to="whoweare"
                smooth={true}
                // offset={50}
                duration={500}
                className="footerlink"
              >
                <FormattedMessage id="whoWeAreHeader" />
              </Link>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/contactus">
              <Link
                style={{ width: "220px", height: "260px" }}
                activeClass="active"
                to="contactus"
                smooth={true}
                // offset={50}
                duration={500}
                className="footerlink"
              >
                <FormattedMessage id="contactHeader" />
              </Link>
            </Nav.Link>
          </div>
        </div>


        <div className="footerourservicezone">
          <div className="footertopicname">

            <FormattedMessage id="ourservice" />

          </div>
          <div className="footerourservicecontent">
            {infoService.map((item, index) => (
              <div className="content-in-card">
                <Link
                  style={{ width: "220px", height: "260px", cursor: "pointer" }}
                  activeClass="active"
                  to={`card.${index}`}
                  smooth={true}
                  offset={-200}
                  duration={500}
                  className="footerlink">
                  {item.title}
                </Link>
                {/* <a className={`content-card-service-${locale}`}>

                </a> */}
              </div>
            ))}
          </div>
        </div>


        <div className="footercontactuszone">
          <div className="footertopicname">

            <FormattedMessage id="contactus" />

          </div>
          <div className="footercontactuscontent">
            <>contactme@rapidtech.app</>
            <div className="flex-column">+6696 992 9595</div>

            <div className="footersocial" style={{ padding: "none" }}>
              <a
                href="https://www.linkedin.com/company/rapidtechth"
                // target="_blank"
              >
                <img src={Footer_linkedin} className="footerlinkedin" alt="LinkIn_logo"/>
              </a>
              <a href="https://www.facebook.com/rapidtech.th">
                <img src={Footer_Facrbook} alt="Facebook_logo"/>
              </a>
            </div>
          </div>
        </div>

        <div className="footerprivacyzone">
          <div className="footertopicname" >

            <FormattedMessage id="cookie-ft-1" />

          </div>
          <div className="footercontactuscontent" >
            <div style={{cursor: "pointer"}} onClick={handleShowPolicyModal}><FormattedMessage id="cookie-ft-2" /></div>
            <div className="flex-column" style={{cursor: "pointer"}} onClick={() => setVisibleCookieWeb(true)}><FormattedMessage id="cookie-ft-3" /></div>

          </div>
        </div>

      </div>

      


      <div
        style={{ fontSize: "14px ", color: "rgb(29, 53, 87)", display: "grid" }}
      >
        All Rights Reserved ©RAPIDTECH Co., Ltd.
      </div>
      <img src={Footer_line} className="footercopyright" alt="Line_logo"/>
    </div>
  )
}

export default Footer
