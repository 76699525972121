import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Container, Modal, Row, Col, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircle } from '@fortawesome/free-solid-svg-icons';
import PolicyPattern from '../asset/image/Policy_Pattern.png'
import ContactShapewithClose from '../asset/image/ContactShape_with_Close.png'
import '../asset/css/cookie-setting-web.css'

function CookieSettingWeb(props) {
  const modalProps = { ...props };
  _.unset(modalProps, ['locale']);
  const { locale, onHide, checkedSwitchCookie, onSwitchCookie, SetAllCookies, clearCookie } = props;
  // const [statusRead, setStatusRead] = useState(true);
  const listInnerRef = useRef();

  // useEffect(() => {
  //   setStatusRead(true);
  // }, [show]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= (scrollHeight - 50)) {
        // setStatusRead(false);
      }
    }
  };

  return (
    <Modal
      {...modalProps}
      className='cookie-setting-policy-modal'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <img src={PolicyPattern} className='img-close-button-1' alt='Close_IMG1' />
        <img src={ContactShapewithClose} alt='Close_IMG2' className='img-close-button-2' onClick={() => {
          onHide()
          clearCookie()
        }} />
      </Modal.Header>
      <div style={{ position: 'relative', top: '-60px' }}>
        <h1 className={`contact-policy-header-${locale}`} style={{ marginBottom: '10px' }}><FormattedMessage id='cookie-st-1' /></h1>
        <div
          className={`contact-policy-content`}
          style={{ maxHeight: '600px', marginTop: 'unset' }}
          onScroll={onScroll}
          ref={listInnerRef}
        >
          <Modal.Body>
            <div>
              <Container fluid='xl'>
                <Row>
                  <Col lg={12}>
                    <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-35 cookie-modal-switch-setting`}>
                      <FormattedMessage id='cookie-st-2' />
                      <span style={{ color: '#c4c4c4' }}><FormattedMessage id='cookie-st-10' /></span>
                    </p>

                    <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                      <FormattedMessage id='cookie-st-3' />
                    </p>

                    <p className={`pdt-cookie contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                      <FormattedMessage id='cookie-st-4' />
                      <label class="cookie-modal-switch">
                        <input type="checkbox" checked={_.get(checkedSwitchCookie, 'analytical')} onClick={() => onSwitchCookie('analytical')} />
                        <span class="cookie-modal-slider round"></span>
                      </label>
                    </p>

                    <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                      <FormattedMessage id='cookie-st-5' />
                    </p>

                    <p className={`pdt-cookie contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                      <FormattedMessage id='cookie-st-6' />
                      <label class="cookie-modal-switch">
                        <input type="checkbox" checked={_.get(checkedSwitchCookie, 'functionality')} onClick={() => onSwitchCookie('functionality')} />
                        <span class="cookie-modal-slider round"></span>
                      </label>
                    </p>

                    <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                      <FormattedMessage id='cookie-st-7' />
                    </p>

                    <p className={`pdt-cookie contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                      <FormattedMessage id='cookie-st-11' />
                      <label class="cookie-modal-switch">
                        <input type="checkbox" checked={_.get(checkedSwitchCookie, 'target')} onClick={() => onSwitchCookie('target')} />
                        <span class="cookie-modal-slider round"></span>
                      </label>
                    </p>

                    <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                      <FormattedMessage id='cookie-st-12' />
                    </p>


                    <p className={`pdt-cookie contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50 cookie-modal-switch-setting`}>
                      <FormattedMessage id='cookie-st-13' />
                      <label class="cookie-modal-switch">
                        <input type="checkbox" checked={_.get(checkedSwitchCookie, 'advertising')} onClick={() => onSwitchCookie('advertising')} />
                        <span class="cookie-modal-slider round"></span>
                      </label>
                    </p>

                    <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-15`}>
                      <FormattedMessage id='cookie-st-14' />
                    </p>

                  </Col>
                </Row>
              </Container>
            </div>
          </Modal.Body>
        </div>
        <Row lg={12} style={{ margin: '30px 50px 10px 0px', height: '70px' }}>
          <Col lg={12} xs={12} className='t-a-c' style={{ padding: 'unset', display: 'flex', justifyContent: 'end' }}>
            <Button className='cookie-modal-button-confirm' style={{ width: '180px', height: '42px' }} onClick={() => SetAllCookies()}><FormattedMessage id='cookie-st-8' /></Button>
            <Button className='cookie-modal-button-accept' style={{ width: '180px', height: '42px' }} onClick={() => SetAllCookies('ALL')}><FormattedMessage id='cookie-st-9' /></Button>
          </Col>
        </Row>
      </div>

    </Modal>
  );
}

export default CookieSettingWeb